import * as React from "react";
import Header from "../components/sections/Header";
import PortfolioSection from "../components/sections/PortfolioSection";
import Footer from "../components/sections/Footer";
import PageTitle from "../components/elements/PageTitle";

const PortfolioPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Our portfolio"}
      description={`Customers and success cases. Do you want to be among them?`}
    />
    <Header />
    <PortfolioSection
      title={"Our Portfolio"}
      // description={`Our customers we worked with and continue to work in the long term`}
      issuesId={
        "18154,18149,18122,18151,18152,18148,18150,18119,18121,18123,18130,18131"
      }
      sourceChangedTitle={{ 18148: "Watch now", 18151: "Download now", 18154: "Open Regulation" }}
    />
    <Footer />
  </div>
);

export default PortfolioPage;
