import React from "react";
import LazyLoad from "react-lazyload";

const ProjectItem = ({
  id,
  image,
  title,
  description,
  source,
  sourceTitle,
}) => (
  <div key={id}>
    <div className="space-y-4">
      <div className="aspect-w-3 aspect-h-2">
        <LazyLoad once offset={100}>
          <img
            src={image}
            className="object-cover shadow-lg rounded-lg"
            alt={title}
          />
        </LazyLoad>
      </div>

      <div className="space-y-2">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium ">{title}</h3>
          <p className="mt-3 font-base text-base text-gray-500">
            {description}
          </p>
          {source && (
            <a
              href={source.includes("https://") ? source : `https://${source}`}
              className="break-all inline-block mt-3 text-base text-amber-600 hover:text-amber-700"
            >
              {sourceTitle || source}
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ProjectItem;
