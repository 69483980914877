import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { serverUrl, bearerToken } from "../../api/portfolio";
import ProjectItem from "../elements/ProjectItem";
import Title from "../elements/Title";

const PortfolioSection = ({
  title,
  description,
  issuesId,
  sourceChangedTitle,
}) => {
  const [projects, setProjects] = useState();
  const sourceTitlesKeys = sourceChangedTitle
    ? Object.keys(sourceChangedTitle)
    : null;

  const getPortfolio = async () => {
    const resp = await fetch(serverUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({ issues: issuesId.replace(/\s/g, "") }),

      mode: "cors",
      cache: "default",
    });
    const data = await resp.json();

    let projects = {};

    for (let i = 0; i < data.length; i++) {
      projects[data[i].id] = data[i];
    }

    const ids = issuesId.replace(/\s/g, "").split(",");

    let res = [];

    for (let i = 0; i < ids.length; i++) {
      let project = projects[ids[i]];
      if (project) {
        if (sourceChangedTitle) {
          for (let j = 0; j < sourceTitlesKeys.length; j++) {
            if (project.id === Number(sourceTitlesKeys[j]))
              project.sourceTitle = sourceChangedTitle[sourceTitlesKeys[j]];
          }
        }
        res.push(project);
      }
    }

    setProjects(res);
  };

  useEffect(() => {
    getPortfolio();
  }, []);

  return (
    <div className="bg-white">
      <Title title={title} description={description} />
      <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
        <div className="space-y-12">
          {/* Projects list */}
          <div className="mt-12 space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            {projects &&
              projects.map((item) => (
                <ProjectItem
                  id={item.id}
                  image={item.image}
                  title={item.title}
                  description={item.description}
                  source={item.source}
                  sourceTitle={item.sourceTitle}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PortfolioSection;
